<template>
  <div class="main__content">
    <div class="table__header_bar control-panel">
      <div>
        <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      </div>
      <div class="btn-wrapper">
        <Button
          :disabled="isReportsTableExistsDraft || isExistingReport"
          isControl
          iconName="plus"
          @click.native="createReport"
        >
          {{ $t('buttons.create_draft') }}
        </Button>
        <div class="tooltip-wrapper" v-if="isExistingReport">
          <Tooltip
            :text="$t('tooltip.report_is_send_in_cur_reriod')"
            position="top"
            :is-dynamic="true"
          >
            <div class="tooltip-content" />
          </Tooltip>
        </div>
      </div>
    </div>
    <Table
      v-if="!!getTourOperatorReportsTable"
      :content="getTourOperatorReportsTable.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/elements/Table';
export default {
  components: {
    Table,
  },
  data() {
    return {};
  },

  async created() {
    await this.$store.dispatch('getTourOperatorReportsTable');
  },

  computed: {
    ...mapGetters([
      'getTourOperatorReportsTable',
      'isReportsTableExistsDraft',
      'isExistingReport',
    ]),
  },

  methods: {
    createReport() {
      this.$router.push({
        name: 'tour-operator_report-create',
        params: {
          activity: 'create',
          rooth_name: this.$route.name,
        },
      });
    },

    tableHandler() {
      this.$store.dispatch('getTourOperatorReportsTable');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTourOperatorReportsTable');
    },
  },
};
</script>

<style lang="sass" scoped>
.btn-wrapper
  position: relative

.tooltip-wrapper, .tooltip-content
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
</style>
